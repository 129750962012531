import { useContext, useEffect, useState } from "react";
import CardContractualParameter from "./CardContractualParameter";
import { ContactedContext } from "../../../context/Dashboard/ContactedContext";
import FilterContacted from "./FilterContacted";
import moment from "moment";

const ContactedVSAccomplished = ({ type }) => {
  const { receipt, weekOrMonth, typeUnit, receiptParameter, receiptOkOrNok } =
    useContext(ContactedContext);
  // Declaração dos useState
  const [dates, setDates] = useState([]);
  const [datesOkAndNok, setDatesOkAndNok] = useState([]);
  const [weeks, setWeeks] = useState([]);

  const [quantityEntries, setQuantityEntries] = useState([]);
  const [lineEntries, setLineEntries] = useState([]);
  const [lineOk, setLineOk] = useState([]);

  const [quantityConferences, setQuantityConferences] = useState([]);
  const [lineConferences, setLineConferences] = useState([]);
  const [lineNOk, setLineNOk] = useState([]);

  const [metrics, setMetrics] = useState(0);
  const [lineOrQtd, setLineOrQtd] = useState("line");

  const onTitle = () => {
    const data = {
      Recebimento: "Recebimento - Contratado",
      Saida: "Expedição - Contratado",
      Urgente: "Urgência - Contratado",
    };

    return data[typeUnit];
  };

  const onTitleOkAndNok = () => {
    const data = {
      Recebimento: "Recebimento",
      Saida: "Expedição",
      Urgente: "Urgência",
    };

    return data[typeUnit];
  };

  const visible = () => {
    if (type === "Contratada vs Realizada") return true;
    return false;
  };

  const onSeparateEntries = () => {
    // Extraindo os dados do receipt e armazenando nos estados

    const date = receipt?.map((elem) =>
      weekOrMonth == "week" ? elem.dayOfWeek : elem.date
    );
    setDates(date);

    const dateOkAndNok = receiptOkOrNok?.map((elem) =>
      moment(elem.date).format("DD/MM")
    );
    setDatesOkAndNok(dateOkAndNok);

    const week = receipt?.map((elem) => elem.dayOfWeek);
    setWeeks(week);

    const quantityEntry = receipt?.map((elem) => elem.entry.quantity_entry);
    console.log(receipt, 2020);

    setQuantityEntries(quantityEntry);

    const lineEntry = receipt?.map((elem) => elem.entry.line_entry);
    setLineEntries(lineEntry);

    const quantityConference = receipt?.map(
      (elem) => elem.conference.quantity_conference
    );
    setQuantityConferences(quantityConference);

    const lineConference = receipt?.map(
      (elem) => elem.conference.line_conference
    );
    setLineConferences(lineConference);

    const linesOk = receiptOkOrNok.map((elem) => elem.qtdLinhaOk);
    setLineOk(linesOk);

    const linesNOk = receiptOkOrNok.map((elem) => elem.qtdLinhaNok);
    setLineNOk(linesNOk);

    const metric = receiptParameter.find((elem) => {
      if (elem.type === typeUnit)
        if (lineOrQtd === "line" && elem.line_or_qtd === "Linha") return elem;
        else if (lineOrQtd === "qtd" && elem.line_or_qtd === "Quantidade")
          return elem;
    })?.metric;
    setMetrics(metric);
  };

  const onVisibleEntriesLineOrQtd = () => {
    if (lineOrQtd === "line") return lineEntries;
    if (lineOrQtd === "qtd") return quantityEntries;
  };

  const onVisibleConferencesLineOrQtd = () => {
    if (lineOrQtd === "line") return lineConferences;
    if (lineOrQtd === "qtd") return quantityConferences;
  };

  useEffect(() => {
    onSeparateEntries();
  }, [receipt, lineOrQtd]);

  return (
    visible() && (
      <div
        style={{
          marginTop: "80px",
          display: "flex",
          flexDirection: "column",
          gap: "30px",
        }}
      >
        {/* <h4 className="textContactedVSAccomplished">Contratada vs Realizada</h4> */}

        <CardContractualParameter
          max={100}
          title={`${onTitle()} vs Conferido`}
          content={"Parâmetro contratual"}
          contentTwo={{ label: "Contratado", cicle: "#d7d7d7" }}
          contentThree={{ label: "Conferido", cicle: "var(--color-primary)" }}
          metrics={metrics ?? 0}
          listMonths={onVisibleConferencesLineOrQtd()}
          listMonthsTwo={onVisibleEntriesLineOrQtd()}
          labels={dates}
          labelColOne={"Conferido"}
          labelColTwo={"Contratado"}
          height={"auto"}
          heightGraphics={"500px"}
          html={
            <FilterContacted
              visible={() => visible()}
              type={type}
              setLineOrQtd={setLineOrQtd}
            />
          }
        />

        <CardContractualParameter
          max={100}
          title={`${onTitleOkAndNok()} - OK e NOK`}
          content={"Parâmetro contratual"}
          metrics={0}
          listMonths={lineOk}
          listMonthsTwo={lineNOk}
          labels={datesOkAndNok}
          labelColOne={"OK"}
          labelColTwo={"NOK"}
          height={"auto"}
          heightGraphics={"500px"}
        />
      </div>
    )
  );
};

export default ContactedVSAccomplished;
